import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useState } from 'react';
import auth from '@motimate/auth';
import { getClickableProps } from '@ui/utils/a11y';
import { useRouter } from 'next/router';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useOrganizationQuery } from '@/lib/hooks/useOrganization';
import { p } from '@/lib/path-utils';
import { createLoadingSelector } from '@/redux/selectors/core/loading';
import { AuthForm } from '@/auth/Layout';
import { getIsMobileApp, getSearch, getSigninUrl } from '@/auth/utils';
import UIButton from '@/components/ui/button';
import UIInput from '@/components/ui/input';
import UILoader from '@/components/ui/loader';
import UILogo from '@/components/ui/logo';
import { Link } from '@/components/ui/router';
import UISpinner from '@/components/ui/spinner';
import UIText from '@/components/ui/text';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var messages = defineMessages({
  email: {
    id: 'loginForm.usernameInput.placeholder.email'
  },
  employee_number: {
    id: 'loginForm.usernameInput.placeholder.employee_number'
  },
  employee_number_or_email: {
    id: 'loginForm.usernameInput.placeholder.employee_number_or_email'
  }
});
var _ref2 = true ? {
  name: "1l0p3iw",
  styles: "white-space:pre-line"
} : {
  name: "lvwysw-LoginInternal",
  styles: "white-space:pre-line;label:LoginInternal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = true ? {
  name: "ojja9b",
  styles: "cursor:pointer;:hover{text-decoration-line:underline;}"
} : {
  name: "7sern0-LoginInternal",
  styles: "cursor:pointer;:hover{text-decoration-line:underline;};label:LoginInternal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = true ? {
  name: "1bqgyti",
  styles: "text-decoration-line:none;:hover{text-decoration-line:underline;}"
} : {
  name: "gagmoy-LoginInternal",
  styles: "text-decoration-line:none;:hover{text-decoration-line:underline;};label:LoginInternal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var LoginInternal = function LoginInternal(_ref) {
  var _organization$colors$;
  var errorMessage = _ref.errorMessage,
    onLogin = _ref.onLogin;
  var _useIntl = useIntl(),
    formatMessage = _useIntl.formatMessage;
  var router = useRouter();
  var _useState = useState(''),
    password = _useState[0],
    setPassword = _useState[1];
  var _useState2 = useState(''),
    username = _useState2[0],
    setUsername = _useState2[1];
  var isAuthLoading = useSelector(createLoadingSelector(['AUTH_LOGIN']));
  var isUserLoading = useSelector(createLoadingSelector(['USER_FETCH']));
  var _useOrganizationQuery = useOrganizationQuery({
      redux: false
    }),
    organization = _useOrganizationQuery.data;
  var isMobileApp = getIsMobileApp(router);
  var disabled = !password || !username || isAuthLoading;
  var handleChangeOrganizationClick = function handleChangeOrganizationClick() {
    auth.removeOrganization();
    router.push(getSigninUrl(router));
  };
  var handleSubmit = function handleSubmit(event) {
    event.preventDefault();
    onLogin(username, password);
  };
  if (!organization || isUserLoading) {
    return _jsx(UILoader, {});
  }
  var identifierType = organization.authentication.identifier_type;
  return _jsxs(AuthForm, {
    "data-testid": "page-auth-login",
    onSubmit: handleSubmit,
    children: [_jsx(UILogo, {
      href: p('/'),
      src: organization.assets.logo,
      title: organization.name
    }), _jsx(UIInput, {
      "aria-label": formatMessage(messages[identifierType]),
      autoComplete: identifierType === 'email' ? 'email' : 'username',
      placeholder: formatMessage(messages[identifierType]),
      type: identifierType === 'email' ? 'email' : 'text',
      variant: "white",
      onChange: function onChange(event) {
        setUsername(event.target.value);
      },
      "data-testid": "login-input"
    }), _jsx(UIInput, {
      "aria-label": formatMessage({
        id: 'loginForm.passwordInput.placeholder'
      }),
      autoComplete: "current-password",
      placeholder: formatMessage({
        id: 'loginForm.passwordInput.placeholder'
      }),
      type: "password",
      variant: "white",
      onChange: function onChange(event) {
        setPassword(event.target.value);
      },
      "data-testid": "password-input"
    }), _jsxs(UIButton, {
      disabled: disabled,
      size: "semiXlarge",
      variant: "primary",
      textTransform: "uppercase",
      $themeColorPrimary: (_organization$colors$ = organization === null || organization === void 0 ? void 0 : organization.colors.main) !== null && _organization$colors$ !== void 0 ? _organization$colors$ : '',
      "data-testid": "sign-in-button",
      children: [_jsx(FormattedMessage, {
        id: "loginForm.submitButton",
        tagName: "span"
      }), isAuthLoading && _jsx(UISpinner, {
        size: "large",
        variant: "white"
      })]
    }), errorMessage && _jsx(UIText, {
      "data-testid": "page-auth-fail-message",
      css: _ref2,
      variant: "danger",
      children: errorMessage
    }), !isMobileApp && _jsx(UIText, _objectSpread(_objectSpread({
      variant: "primary",
      css: _ref3
    }, getClickableProps(handleChangeOrganizationClick)), {}, {
      children: _jsx(FormattedMessage, {
        id: "loginForm.changeOrganization"
      })
    })), _jsx(Link, {
      href: p("/password/reset".concat(getSearch(router))),
      css: _ref4,
      children: _jsx(UIText, {
        variant: "light",
        children: _jsx(FormattedMessage, {
          id: "resetPasswordLink.text"
        })
      })
    })]
  });
};
export default LoginInternal;