import { useState } from 'react';
import auth from '@motimate/auth';
import { isAmpliLoaded } from '@/lib/amplitude-utils';
import { getIdentifier } from '@/lib/url-utils';
import { ampli } from '@/ampli';
import LoginInternal from './LoginInternal';
import LoginThirdParty from './LoginThirdParty';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var Login = function Login(_ref) {
  var _ref$initialErrorMess = _ref.initialErrorMessage,
    initialErrorMessage = _ref$initialErrorMess === void 0 ? null : _ref$initialErrorMess,
    onLoginSuccess = _ref.onLoginSuccess;
  var _useState = useState(initialErrorMessage),
    errorMessage = _useState[0],
    setErrorMessage = _useState[1];
  var organization = getIdentifier();
  var isAuthThirdPartyMechanism = auth.isThirdPartyMechanism(organization) || auth.isThirdPartyOAuthMechanism(organization);
  var handleLogin = function handleLogin(username, password) {
    setErrorMessage(null);
    auth.login(username, password, organization).then(onLoginSuccess)["catch"](function (error) {
      var _ref2, _error$response$data$, _error$response2;
      isAmpliLoaded(function () {
        var _error$response;
        ampli.userLoginFailed({
          loginOrgIdentifier: organization !== null && organization !== void 0 ? organization : 'unknown',
          loginType: 'internal',
          errorMessage: error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 || (_error$response = _error$response.data) === null || _error$response === void 0 || (_error$response = _error$response.error) === null || _error$response === void 0 ? void 0 : _error$response.message
        });
      });
      setErrorMessage((_ref2 = (_error$response$data$ = error === null || error === void 0 || (_error$response2 = error.response) === null || _error$response2 === void 0 || (_error$response2 = _error$response2.data) === null || _error$response2 === void 0 || (_error$response2 = _error$response2.error) === null || _error$response2 === void 0 ? void 0 : _error$response2.message) !== null && _error$response$data$ !== void 0 ? _error$response$data$ : error === null || error === void 0 ? void 0 : error.message) !== null && _ref2 !== void 0 ? _ref2 : 'Unexpected');
    });
  };
  if (isAuthThirdPartyMechanism) {
    return _jsx(LoginThirdParty, {});
  }
  return _jsx(LoginInternal, {
    "data-testid": "page-auth-login",
    errorMessage: errorMessage,
    onLogin: handleLogin
  });
};
export default Login;